import { useEffect, useState } from 'react'
import { SmartWeaveWebFactory, LoggerFactory } from 'redstone-smartweave'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import { useCurrentWidth } from 'react-breakpoints-hook'
import Twitter from '../assets/twitter2021.png'
import Arconnect from '../assets/arconnect3.png'
import Ardrive from '../assets/ardrive3.png'
import { BsCheck } from 'react-icons/bs'
import Arweave from 'arweave'
import ArDB from 'ardb'
import Swal from 'sweetalert2'
import { useStepContext } from '../contexts/StepContext'
import { useUserContext } from '../contexts/UserContext'
import { useControlContext } from '../contexts/ControlContext'
import './cards.css'
import Mint from './mint'
import NftBlock from './nftBlock'

const arweave = Arweave.init({
  host: 'arweave.net',
  port: 443,
  protocol: 'https',
  timeout: 100000,
  logging: false
})

LoggerFactory.INST.logLevel('error')
const smartweave = SmartWeaveWebFactory.memCached(arweave)

const ardb = new ArDB(arweave)

export default function Cards () {
  const [arconnectInstalled, setArconnectInstalled] = useState(false)
  const [verifiedClassTwitter, setVerifiedTwitterClass] = useState('')
  const [verifiedClassArdrive, setVerifiedClassArdrive] = useState('')
  const [twitterFollow, setTwitterFollow] = useState(false)
  const [ardriveUploads, setArdriveUploads] = useState(false)
  const [loader, setLoader] = useState(false)
  const [NFTsRemaining, setNFTsRemaining] = useState(0)
  const [totalNFTs, setTotalNFTs] = useState(0)
  const [noMoreNFTs, setNoMoreNFTs] = useState(false)

  const { accessGranted, handleAccessGranted } = useStepContext()
  const { activeAddress, handleActiveAddress } = useUserContext()
  const { control } = useControlContext()
  const width = useCurrentWidth()
  const mobileBreakpoint = width < 450
  const mintButtonAppears = accessGranted && !mobileBreakpoint && !noMoreNFTs

  useEffect(() => {
    let address
    window.addEventListener('arweaveWalletLoaded', async () => {
      try {
        await window.arweaveWallet.connect(
          ['ACCESS_ADDRESS', 'ACCESS_ALL_ADDRESSES'],
          {
            name: 'ArweaveOnboardingAdventure'
          }
        )
        address = await window.arweaveWallet.getActiveAddress()

        handleActiveAddress(address)
        setArconnectInstalled(true)
      } catch (e) {}
    })
    window.addEventListener('walletSwitch', e => {
      address = e.detail.address
      handleActiveAddress(address)
    })
  }, [])

  // TODO: maybe use another hook to handle this...
  useEffect(() => {
    const fetchRemainingNFTs = async () => {
      const aoaContractTxId = 'SgHd_L9CLLHWeiqBAp_zuqiNNbGcbKI5pc9IXh9RqZ8'
      const contractAOA = smartweave.contract(aoaContractTxId)
      const stateAOA = await contractAOA.readState()
      const arrayNFTs = stateAOA?.state?.NFTs
      if (!arrayNFTs) {
        return
      }
      const claimedNFTs = arrayNFTs.filter(nft => nft.status !== 0)
      // Uncomment the code below to print contract states to console
      // console.log('Claimed NFTs: ', claimedNFTs.length)
      // console.log('Contract state', JSON.stringify(stateAOA, null, 2))
      // const lastClaimedNFTindex = claimedNFTs.length
      //   ? claimedNFTs?.length - 1
      //   : 0
      // const nftContract = smartweave.contract(arrayNFTs[lastClaimedNFTindex].id)
      // const nftState = await nftContract.readState()
      // console.log('NFT state', JSON.stringify(nftState, null, 2))
      const nftCountDiff = arrayNFTs.length - claimedNFTs.length
      if (nftCountDiff <= 0) setNoMoreNFTs(true)
      setNFTsRemaining(nftCountDiff)
      setTotalNFTs(arrayNFTs.length)
    }
    fetchRemainingNFTs()
  }, [control])

  useEffect(() => {
    window.twttr = (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {}
      if (d.getElementById(id)) return t
      js = d.createElement(s)
      js.id = id
      js.src = 'https://platform.twitter.com/widgets.js'
      fjs.parentNode.insertBefore(js, fjs)

      t._e = []
      t.ready = function (f) {
        t._e.push(f)
      }

      return t
    })(document, 'script', 'twitter-wjs')

    if (localStorage.getItem('onlyArweave') === 'true') {
      setVerifiedTwitterClass('success')
      setTwitterFollow(true)
    }

    window.twttr.ready(function (twttr) {
      twttr.events.bind('follow', function (event) {
        if (event.data.screen_name === 'onlyarweave') {
          localStorage.setItem('onlyArweave', true)
          setVerifiedTwitterClass('success')
          setTwitterFollow(true)
        }
      })
    })
  }, [])

  useEffect(() => {
    if (activeAddress && twitterFollow && ardriveUploads && control) {
      handleAccessGranted(true)
    } else {
      handleAccessGranted(false)
    }
  }, [
    activeAddress,
    twitterFollow,
    ardriveUploads,
    handleAccessGranted,
    control
  ])

  const triggerAppInstallAlert = (
    title,
    app,
    icon,
    url,
    imageWidth = '100%'
  ) => {
    Swal.fire({
      title: `<h4>${title}</h4>`,
      html:
        'If you need assistance, tweet <a href="http://twitter.com/onlyarweave">@onlyarweave</a>',
      imageUrl: icon,
      imageWidth,
      showDenyButton: true,
      showConfirmButton: true,
      denyButtonText: 'Later',
      confirmButtonText: `${app === 'ArDrive' ? 'Use' : 'Install'} ${app}`,
      confirmButtonColor: '#07ABF2',
      denyButtonColor: 'gray'
    }).then(value => {
      if (value.isConfirmed) {
        window.open(url, '_blank').focus()
      }
    })
  }

  const onInstallArConnectClick = async () => {
    if (activeAddress) return
    triggerAppInstallAlert(
      'Install ArConnect to complete this step',
      'Arconnect',
      Arconnect,
      'https://arconnect.io',
      '50%'
    )
  }

  const onArDriveClick = async () => {
    try {
      setLoader(true)
      if (!activeAddress) {
        setLoader(false)
        triggerAppInstallAlert(
          'You must first install Arconnect before checking your ArDrive uploads',
          'ArConnect',
          Arconnect,
          'https://arconnect.io',
          '50%'
        )
        return
      }
      const txsArdriveWeb = await ardb
        .search('transactions')
        .from(activeAddress)
        .appName('ArDrive-Web')
        .limit(4)
        .find()
      // Adding a timer for the second ardb query in order to prevent hitting the gateway's rate limiting policy.
      setTimeout(async () => {
        const txsArdriveApp = await ardb
          .search('transactions')
          .from(activeAddress)
          .appName('ArDrive-App')
          .limit(4)
          .find()
        const txs = [...txsArdriveWeb, ...txsArdriveApp]
        // console.log('txsArdriveApp', txsArdriveApp)
        // console.log('txsArdriveWeb', txsArdriveWeb)
        // console.log('txs', txs)
        if (!txs) return
        const txsLength = txs.length
        if (txsLength || txsLength === 0) setLoader(false)
        if (txsLength >= 3) {
          setArdriveUploads(true)
          setVerifiedClassArdrive('success')
        } else {
          triggerAppInstallAlert(
            "You don't have enough Ardrive uploads. Go to ArDrive to upload at least three assets",
            'ArDrive',
            Ardrive,
            'https://ardrive.io',
            '70%'
          )
        }
      }, 2000)
    } catch (error) {
      console.log('ArDrive step error', error)
    }
  }

  return (
    <div className='cards-row d-flex'>
      <Container fluid className='mb-4'>
        <h1 className='mb-2 p-3 hero-title'>
          Genesis Release. <br />
          Get a limited edition Arweave NFT.
        </h1>
        <div>
          <img
            alt='animated onboarder coin'
            className='onboarder-coin'
            src='https://cdn.discordapp.com/attachments/902715455872057455/1007864402185293955/og-arweave-coin-onboarder.gif'
          ></img>
        </div>
        {mintButtonAppears ? (
          <Mint />
        ) : (
          <>
            <div className='counter-container p-2 px-4'>
              {totalNFTs - NFTsRemaining} of {totalNFTs} Minted
            </div>
            {noMoreNFTs && (
              <p className='no-more-nfts'>
                All NFTs have been claimed! Follow{' '}
                <a className='text-white' href='https://twitter.com/onlyarweave' target='blank'>
                  @OnlyArweave
                </a>{' '}
                for future releases.
              </p>
            )}
          </>
        )}
        {mobileBreakpoint && (
          <p className='mobile-alert'>
            The minting process is only available on desktop or tablets that
            allow browser extensions
          </p>
        )}
        <Row className='mt-5 mx-md-3 trio'>
          <Col xs={12} md={4} className='mb-5 px-4'>
            <Card
              border='primary'
              className='h-100 align-items-center justify-content-between'
            >
              <div className='d-flex justify-content-center'>
                <h2 className='step d-flex justify-content-center align-items-center'>
                  1
                </h2>
              </div>
              <Card.Title className='card-title'>
                <h3 className='mx-4'>
                  Get the ArConnect wallet browser extension.
                </h3>
              </Card.Title>
              <Card.Img
                className='mb-3'
                style={{ maxWidth: '33%' }}
                alt='arconnect logo'
                src={Arconnect}
              />
              <div className='p-3 w-100 footer-content'>
                {arconnectInstalled ? (
                  <Button
                    variant='success'
                    className='wv-card-button wv-card-button-success cta-button w-100'
                    style={{ color: '#FFFFFF !important' }}
                    disabled={mobileBreakpoint}
                  >
                    <BsCheck />
                    ArConnect installed
                  </Button>
                ) : (
                  <Button
                    onClick={() => onInstallArConnectClick()}
                    variant='primary'
                    className='wv-card-button cta-button w-100'
                    disabled={mobileBreakpoint}
                  >
                    Install ArConnect
                  </Button>
                )}
                <Card.Text className='small card-footer-text'>
                  <span className='m-0'>
                    This is where you'll keep your $AR tokens.
                  </span>
                </Card.Text>
              </div>
            </Card>
          </Col>

          <Col xs={12} md={4} className='mb-5 px-4'>
            <Card
              border='primary'
              className='h-100 align-items-center justify-content-between'
            >
              <div className='d-flex justify-content-center'>
                <h2 className='step d-flex justify-content-center align-items-center'>
                  2
                </h2>
              </div>
              <Card.Title className='card-title mt-sm-2'>
                <h3 className='mx-4'>
                  Follow <b>@onlyarweave</b> on Twitter to complete this step!
                </h3>
              </Card.Title>
              <Card.Img
                alt='ardrive logo'
                className='mb-3'
                style={{ maxWidth: '28%' }}
                src={Twitter}
              />
              <div className='p-3 w-100 footer-content'>
                {verifiedClassTwitter ? (
                  <Button
                    href='https://twitter.com/intent/follow?screen_name=onlyarweave'
                    data-show-count='false'
                    variant='success'
                    className='wv-card-button wv-card-button-success cta-button w-100'
                    disabled={mobileBreakpoint}
                  >
                    <BsCheck />
                    Followed
                  </Button>
                ) : (
                  <Button
                    href='https://twitter.com/intent/follow?screen_name=onlyarweave'
                    data-show-count='false'
                    variant='primary'
                    className='wv-card-button cta-button w-100'
                    disabled={mobileBreakpoint}
                  >
                    Follow
                  </Button>
                )}

                <Card.Text className='small card-footer-text'>
                  <span className='m-0'>
                    {`You’re good to go once you follow this account :)`}
                  </span>
                </Card.Text>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={4} className='mb-5 px-4'>
            <Card
              border='primary'
              className='h-100 align-items-center justify-content-between'
            >
              <div className='d-flex justify-content-center'>
                <h2 className='step d-flex justify-content-center align-items-center'>
                  3
                </h2>
              </div>
              <Card.Title className='card-title'>
                <h3 className='mx-4'>
                  Upload your very first file to the Permaweb.
                </h3>
              </Card.Title>
              <Card.Img
                alt='ardrive logo'
                className='mb-3'
                style={{ maxWidth: '75%' }}
                src={Ardrive}
              />
              <div className='p-3 w-100 footer-content'>
                {verifiedClassArdrive ? (
                  <Button
                    variant={'success'}
                    className='wv-card-button wv-card-button-success cta-button w-100'
                    onClick={() => onArDriveClick()}
                    disabled={mobileBreakpoint}
                  >
                    <BsCheck />
                    Uploads confirmed
                  </Button>
                ) : (
                  <Button
                    variant={'primary'}
                    className='wv-card-button cta-button w-100'
                    onClick={() => onArDriveClick()}
                    disabled={mobileBreakpoint}
                  >
                    {loader ? 'Checking uploads...' : 'Check my status'}
                  </Button>
                )}

                <Card.Text className='small card-footer-text'>
                  <span className='m-0'>
                    You'll need at least three uploads to ArDrive
                  </span>
                </Card.Text>
              </div>
            </Card>
          </Col>
        </Row>
        <div className='mx-md-3 trio'>
          <NftBlock />
        </div>
      </Container>
    </div>
  )
}
