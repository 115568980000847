import { React } from 'react'
import { Image } from 'react-bootstrap'
import atomic from '../assets/atomic.png'
import './nftBlock.css'

const NftBlock = () => {
  return (
    <div className='hero-secondary'>
      <div className='details-container px-4 pt-3 pb-2 mb-5'>
        <h3 style={{ fontWeight: '700' }}>Details</h3>
        <p>
          This genesis drop is the first “Arweave Adventure” where individuals
          can explore the Permaweb and earn NFTs. The first series only has 25
          editions.
        </p>
        <p>
          Once you log in and connect your ArConnect wallet and go through each
          of the steps, please be patient :). You’ll know that you’re mint went
          through once the count of remaining NFTs goes down by one and the NFT
          appears in your ArConnect wallet -> this can take several minutes.{' '}
        </p>
        <p>
          There are no guarantees nor inherent value associated with these NFTs.
          You may experience technical issues - by participating you are aware
          of this and accept that for whatever reason you may not receive the
          NFT, among other potential technical issues. Also, by participating
          you are aware that interactions with wallets are published publicly to
          the blockweave permanently on Arweave.
        </p>
      </div>
      <a className='text-white' href='https://twitter.com/onlyarweave' target='blank'>
        <Image
          className='atomic-logo'
          alt='atomic nft logo'
          src={atomic}
          width='64px'
        />
        <p className='small px-3 m-0'>@onlyarweave</p>
      </a>
      <p className='mt-5'>
        In collaboration with{' '}
        <a className='text-white' href='https://www.longviewlabs.co/' target='blank'>
          Longview Labs
        </a>
      </p>
    </div>
  )
}

export default NftBlock
