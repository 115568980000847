import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { generate } from 'hmac-auth-express'

import { useUserContext } from '../contexts/UserContext'
import { useControlContext } from '../contexts/ControlContext'

import './mint.css'

const Mint = () => {
  const { activeAddress } = useUserContext()
  const { setControl } = useControlContext()
  const [loader, setLoader] = useState(false)

  // HMAC stuff
  const time = Date.now().toString()
  const digest = generate(
    process.env.REACT_APP_SECRET_KEY,
    'sha256',
    time,
    'POST',
    '/mint',
    {
      activeAddress
    }
  ).digest('hex')

  const hmac = `HMAC ${time}:${digest}`

  const handleMinting = async activeAddress => {
    try {
      setLoader(true)
      const post = await axios.post(
        `${process.env.REACT_APP_API_BASE}/mint`,
        {
          activeAddress
        },
        {
          headers: {
            Authorization: hmac
          }
        }
      )
      if (post.status === 200) {
        setControl(false)
        setLoader(false)
        Swal.fire({
          title:
            'Congratulations! You now own one limited edition Arweave NFT!',
          text:
            'Please allow several minutes for the NFT to reach your wallet.',
          icon: 'success'
        })
      }
    } catch (e) {
      setControl(false)
      setLoader(false)
      console.log('error in client mint file', { e })
      const {
        response: {
          data: { error, status }
        }
      } = e
      if (status >= 500) {
        Swal.fire({
          title: 'Oops...',
          text: error,
          icon: 'error'
        })
      } else if (status >= 400) {
        Swal.fire({
          title: 'Oops...',
          text: error,
          icon: 'error'
        })
      } else {
        console.log('Error', e)
        Swal.fire({
          title:
            'Oops... Something went wrong! Please refresh the page and try again.',
          text: error,
          icon: 'error'
        })
      }
    }
  }

  return (
    <>
      <button
        className='mint-button'
        onClick={() => handleMinting(activeAddress)}
      >
        {loader ? 'Checking availability...' : 'MINT NFT' }
      </button>
      <p className='psa-message'>
        Allow several minutes for it to enter your wallet
      </p>
    </>
  )
}

export default Mint
