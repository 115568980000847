import React, { createContext, useContext, useState } from 'react'

const StepContext = createContext(null)

const useStepContext = () => {
  const [accessGranted, setAccessGranted] = useContext(StepContext)

  const handleAccessGranted = value => {
    setAccessGranted(value)
  }

  return {
    accessGranted,
    handleAccessGranted
  }
}

const StepContextProvider = ({ children }) => {
  const [accessGranted, handleAccessGranted] = useState(false)

  return (
    <StepContext.Provider value={[accessGranted, handleAccessGranted]}>
      {children}
    </StepContext.Provider>
  )
}

export { StepContextProvider, useStepContext }
