import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import Cards from './components/cards.jsx'
import Header from './components/header'
import { StepContextProvider } from './contexts/StepContext.jsx'
import { UserContextProvider } from './contexts/UserContext.jsx'
import { ControlContextProvider } from './contexts/ControlContext.jsx'

function App () {
  return (
    <UserContextProvider>
      <StepContextProvider>
        <ControlContextProvider>
          <div className='App'>
            <div className='blue-grad'>
              <Header />
              <Cards />
            </div>
          </div>
        </ControlContextProvider>
      </StepContextProvider>
    </UserContextProvider>
  )
}

export default App
