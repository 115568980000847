import React, { createContext, useContext, useState } from 'react'

const UserContext = createContext(null)

const useUserContext = () => {
  const [activeAddress, setActiveAddress] = useContext(UserContext)

  const handleActiveAddress = value => {
    setActiveAddress(value)
  }

  return {
    activeAddress,
    handleActiveAddress
  }
}

const UserContextProvider = ({ children }) => {
  const [activeAddress, handleActiveAddress] = useState('')

  return (
    <UserContext.Provider value={[activeAddress, handleActiveAddress]}>
      {children}
    </UserContext.Provider>
  )
}

export { UserContextProvider, useUserContext }
