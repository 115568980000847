import React, { createContext, useContext, useState } from 'react'

const ControlContext = createContext(null)

const useControlContext = () => {
  const [control, setControlState ] = useContext(ControlContext)

  const setControl = value => {
    setControlState(value)
  }

  return {
    control,
    setControl
  }
}

const ControlContextProvider = ({ children }) => {
  const [control, setControl] = useState(true)

  return (
    <ControlContext.Provider value={[control, setControl]}>
      {children}
    </ControlContext.Provider>
  )
}

export { ControlContextProvider, useControlContext }
