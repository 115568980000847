import { React } from 'react'
import { Navbar, Container } from 'react-bootstrap'

const buttonStyles = {
  borderRadius: '27px',
  backgroundColor: '#F8F8F8',
  border: 'none',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: '17px',
  padding: '6px 34px',
  textDecoration: 'none',
  color: '#2D2D2D'
}

const Header = () => {
  return (
    <div className=''>
      <Navbar style={{ boxShadow: 'none' }}>
        <Container style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a
            style={{
              ...buttonStyles
            }}
            href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=Welcome%20to%20The%20Weave`}
            url='http://dev.twitter.com/pages/tweet_button'
          >
            share
          </a>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header
